import React, { Component } from 'react';
import GradeLandingPage from "./GradeLandingPage";
import SongPage from "../Book/SongPage";
import StorybookPage from "../Book/StorybookPage";
import SheetMusicPage from "../Book/SheetMusicPage";
import SheetMusicPagePdf from "../Book/SheetMusicPagePdf";
import ActivityPage from "../Book/ActivityPage";
import ActivityPagePdf from "../Book/ActivityPagePdf";
import PurchasePage from "../Book/PurchasePage";
import AboutPage from "../StaticPages/AboutPage";
import CreditsPage from "../StaticPages/CreditsPage";
import PrivacyPage from "../StaticPages/PrivacyPage";
import TermsOfUsePage from "../StaticPages/TermsOfUsePage";
import ScrollToTop from "../Helpers/ScrollToTop";
import {
  Route
} from "react-router-dom";

class HomePage extends Component{

  render() {
    return(
      <ScrollToTop>
        <div>
          {/* Grade Landing Page */}
          <Route exact path="/" render={
            (props) => <GradeLandingPage gradeid = "K" books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid" render={
            (props) => <GradeLandingPage books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl" render={
            (props) => <SongPage books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/storybook" render={
            (props) => <StorybookPage books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/sheetmusic" render={
            (props) => <SheetMusicPage books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/sheetmusic/pdfs/:sheetmusicurl" render={
            (props) => <SheetMusicPagePdf books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/activities" render={
            (props) => <ActivityPage books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/activities/pdfs/:pdfurl" render={
            (props) => <ActivityPagePdf books={this.props.books} {...props} />
          } />
          <Route exact path="/grade/:gradeid/:songurl/purchase" render={
            (props) => <PurchasePage books={this.props.books} {...props} />
          } />
          <Route exact path="/about" render={
            (props) => <AboutPage {...props} />
          } />
          <Route exact path="/privacy" render={
            (props) => <PrivacyPage {...props} />
          } />
          <Route exact path="/terms-of-use" render={
            (props) => <TermsOfUsePage {...props} />
          } />
        </div>
      </ScrollToTop>
    )
  }
}

export default HomePage;
