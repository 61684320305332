import React, {Component} from 'react';

class TermsOfUsePage extends Component{
  render() {

    return(
      <div>
        <div class="page-subheader">
          <div class="container flex-container">
            <div class="space">
              <h2>Terms of Use</h2>
              <p>
                PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.
                USE OF THIS SITE MEANS YOU AGREE TO THESE TERMS OF USE.
              </p>
              <p>
                Using the mathmusicals.com website (the “Site”) means you
                consent to these terms of use (the “Terms”). If you do not
                agree, please do not use the Site. If you are under 18 years of
                age, read these Terms with your parent(s) and ask questions
                about things you do not understand.
              </p>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="space half">
            <div class="container content-container">
              <div class="space">
                <div class="space">
                  <div class="space half">
                    <h3>Use of Materials</h3>
                    <p>
                      This Site is owned and operated by Big Ideas Learning, LLC
                      (“Big Ideas Learning” or “We” or “Us”). The contents of
                      this Site are owned by or licensed to Big Ideas Learning
                      and/or third parties and are protected by U.S. and foreign
                      copyright, trademark, and other laws. No material from
                      this Site may be downloaded, printed, copied, reproduced,
                      republished, uploaded, posted, transmitted, sold, or
                      distributed in any way, except:
                    </p>
                    <p>
                      (i) you may download one copy of the sheet music for your
                      personal non-commercial use only, provided you keep intact
                      all copyright, trademark, and other proprietary notices,
                      and
                    </p>
                    <p>
                      (ii) teachers or parents may reprint or photocopy in
                      classroom quantities only Differentiated Rich Math Tasks,
                      which are expressly designated for such use and carry a
                      Big Ideas Learning copyright notice. Such pages are
                      designed to be reproduced by teachers for use in their
                      classes or for parent use in the home, provided each copy
                      made shows the copyright notice. Such copies may not be
                      sold, and further distribution is expressly prohibited.
                    </p>
                    <p>
                      Modification of the materials or use of the materials for
                      any other purpose is prohibited. The use of any such
                      material on any other web site or networked computer
                      environment is prohibited.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>User Conduct</h3>
                    <p>
                      Derogatory, harmful or unlawful conduct is not permitted
                      on this Site. Users are not permitted to upload to,
                      distribute through, or otherwise publish through this Site
                      any content which is libelous, defamatory, obscene,
                      pornographic, threatening, invasive of privacy or
                      publicity rights, abusive, illegal or otherwise
                      objectionable, that would constitute or encourage a
                      criminal offense, violate the rights of any party, or
                      that would otherwise give rise to liability or violate
                      any law. Unauthorized commercial messages and/or public
                      announcements are not allowed on this Site.
                    </p>
                    <p>
                      In addition, users may not:
                    </p>
                    <ul class="term-of-use-list">
                      <li>
                        use this Site for any purpose that is prohibited by any
                        law or regulation, or to facilitate the violation of any
                        law or regulation;
                      </li>
                      <li>
                        use or attempt to use any "deep-link," "scraper,"
                        "robot," "bot," "spider," "data mining," "computer code"
                        or any other automated device, program, tool,
                        algorithm, process or methodology or manual process
                        having similar processes or functionality, to access,
                        acquire, copy, or monitor any portion of this Site,
                        any data or content found on or accessed through this
                        Site, or any other Big Ideas Learning information
                        without our prior written consent;
                      </li>
                      <li>
                        obtain or attempt to obtain through any means any
                        materials or information on this Site that have not been
                        intentionally made publicly available either by their
                        public display on this Site or through their
                        accessibility by a visible link on this Site;
                      </li>
                      <li>
                        violate any measure employed to limit or prevent access
                        to this Site or its content;
                      </li>
                      <li>
                        violate the security of this Site or attempt to gain
                        unauthorized access to this Site, data, materials,
                        information, computer systems or networks connected to
                        any server of this Site, through hacking, password
                        mining or any other means;
                      </li>
                      <li>
                        interfere or attempt to interfere with the proper
                        working of this Site or any activities conducted on or
                        through this Site, including accessing any data, content
                        or other information prior to the time that it is
                        intended to be available to the public on this Site;
                      </li>
                      <li>
                        take or attempt any action that, in our sole discretion,
                        imposes or may impose an unreasonable or
                        disproportionately large load or burden on this Site or
                        the infrastructure of this Site.
                      </li>
                    </ul>
                  </div>

                  <div class="space half">
                    <h3>Third Parties</h3>
                    <p>
                      This Site may provide links to third party websites.
                      Because we do not control such websites, you agree that
                      we are not liable for their content, products or
                      performance. The information practices of those websites
                      are not covered by our Site’s Terms or Privacy Policy, so
                      we recommend that you review any terms of use and privacy
                      policy of any linked third-party website before providing
                      any information to that website or using its products and
                      services.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Changes to Site</h3>
                    <p>
                      We may add, change, discontinue, remove or suspend any
                      portion of this Site at any time, without notice.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Termination</h3>
                    <p>
                      These Terms are effective for the duration of your access
                      to the Site. We reserve the right to terminate your access
                      to the Site immediately without notice, if in our sole
                      discretion you fail to comply with any term or provision
                      of these Terms, if you are a repeat infringer of any third
                      party's rights, or if you engage in conduct that is
                      illegal, tortious or that interferes with the
                      technological operation of this site. Upon termination,
                      you must destroy all materials obtained from this Site and
                      all copies thereof, whether made under these Terms or
                      otherwise.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Disclaimer</h3>
                    <p>
                      BIG IDEAS LEARNING MAKES NO REPRESENTATIONS OR WARRANTIES
                      WITH RESPECT TO THIS SITE OR ITS CONTENTS, WHICH ARE
                      PROVIDED FOR USE "AS IS" AND "AS AVAILABLE." BIG IDEAS
                      LEARNING DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                      INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
                      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
                      WITH RESPECT TO THIS SITE AND ANY WEB SITE WITH WHICH IT
                      IS LINKED. BIG IDEAS LEARNING DOES NOT WARRANT THE
                      FUNCTIONS, INFORMATION OR LINKS CONTAINED ON THIS SITE OR
                      THAT ITS CONTENTS WILL MEET YOUR REQUIREMENTS, THAT THIS
                      SITE, OR ITS CONTENTS, ARE FIT FOR ANY PARTICULAR PURPOSE
                      OR THAT THE OPERATION OF THIS SITE OR ITS CONTENTS, WILL
                      BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
                      CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT
                      AVAILABLE ARE FREE OF VIRUSES, WORMS, TROJAN HORSES,
                      CANCELBOTS OR OTHER HARMFUL COMPONENTS. BIG IDEAS LEARNING
                      DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
                      USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS
                      SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY,
                      OR OTHERWISE. YOU (AND NOT BIG IDEAS LEARNING) ASSUME THE
                      ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR
                      CORRECTION. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF
                      IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY
                      TO YOU.
                    </p>
                    <h4>LIMITATION OF LIABILITY</h4>
                    <p>
                      UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
                      NEGLIGENCE, SHALL BIG IDEAS LEARNING BE LIABLE FOR ANY
                      SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES THAT RESULT
                      FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS
                      ON THIS SITE, EVEN IF WE OR OUR AUTHORIZED REPRESENTATIVE
                      HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                      APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION
                      OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
                      THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                      IN NO EVENT SHALL BIG IDEAS LEARNING'S TOTAL LIABILITY TO
                      YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER
                      IN CONTRACT, OR NOT (INCLUDING, BUT NOT LIMITED TO,
                      NEGLIGENCE) OR OTHERWISE) EXCEED THE AMOUNT PAID BY
                      YOU, IF ANY, FOR ACCESSING THIS SITE.
                      YOU HEREBY ACKNOWLEDGE THAT YOU UNDERSTAND THAT BY
                      ACCESSING THIS SITE AND AGREEING TO THESE TERMS OF USE,
                      YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE
                      AT THIS TIME UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE
                      WITH SUCH WAIVER, YOU ACKNOWLEDGE THAT YOU HAVE READ AND
                      UNDERSTOOD, AND HEREBY EXPRESSLY WAIVE, THE BENEFITS OF
                      SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND ANY
                      SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS
                      FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
                      WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN
                      HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF
                      KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT
                      WITH THE DEBTOR."
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Indemnification</h3>
                    <p>
                      By using this Site, you agree to indemnify, defend and
                      hold harmless Big Ideas Learning, its affiliates, parents,
                      subsidiaries, and divisions, and all of their officers,
                      directors, employees, and agents, from and against any
                      actual or alleged claims, demands, causes of action,
                      judgments, damages, losses, liabilities, and all costs
                      and expenses of defense (including, without limitation,
                      reasonable attorneys' and other legal fees and costs)
                      arising out of or relating to: (1) your violation of
                      these terms of use or any law, rule or regulation; (2)
                      a claim that is based on your use of any content on
                      this Site; or (3) any material uploaded by you or
                      through your computer to this Site or otherwise sent
                      by you to Big Ideas Learning. You will cooperate as
                      fully and reasonably as required by Big Ideas Learning
                      in the defense of any claim. Big Ideas Learning
                      reserves the right to assume the exclusive defense and
                      control of any matter subject to indemnification by
                      you, and you will not in any event settle any claim
                      without the prior written consent of Big Ideas
                      Learning.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Jurisdiction</h3>
                    <p>
                      This Site is controlled and operated by Big Ideas Learning
                      from its offices in Pennsylvania or other locations in the
                      United States of America. We make no representation that
                      materials on this Site are appropriate or available for
                      use in other locations. If you access this Site from other
                      locations, you are responsible for compliance with any
                      applicable local laws. Software from this site is further
                      subject to United States export controls. No software
                      from this Site may be downloaded or otherwise exported or
                      re-exported (i) into (or to a national or resident of)
                      Cuba, Iran, Iraq, Libya, North Korea, Syria or any other
                      country to which the United States has embargoed goods; or
                      (ii) to anyone on the U.S. Treasury Department's list of
                      Specially Designated Nationals or the U.S. Commerce
                      Department's Table of Deny Orders; or (iii) in any other
                      manner that violates U.S. law. By downloading or using the
                      Software, you represent and warrant that you are not
                      located in, under the control of, or a national or
                      resident of any such country or on any such list.
                    </p>
                    <p>
                      These Terms shall be governed by and construed in
                      accordance with the laws of the Commonwealth of
                      Pennsylvania, without giving effect to any principles of
                      conflicts of law. You agree to submit to the exclusive
                      jurisdiction of the courts of the Commonwealth of
                      Pennsylvania and the federal courts of the Western
                      District of Pennsylvania, Erie Division. If any provision
                      of these Terms shall be unlawful, void or for any reason
                      unenforceable, then that provision shall be deemed
                      severable from these Terms and shall not affect the
                      validity and enforceability of any remaining provisions,
                      and such provisions shall be deemed modified so that its
                      purpose can be lawfully effectuated and enforced. This is
                      the entire agreement between the parties relating to the
                      subject matter herein.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Submissions</h3>
                    <p>
                      Big Ideas Learning welcomes suggestions and comments
                      regarding this Site. Any comments or suggestions submitted
                      to us, either online or offline, will become Big Ideas
                      Learning's property upon their submission. This policy is
                      intended to avoid the possibility of future
                      misunderstandings when projects developed by Big Ideas
                      Learning might seem to others to be similar to their own
                      submissions or comments.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Permission for Reprints</h3>
                    <p>
                      All material on this Site without limitation, is protected
                      by U.S. and foreign copyright and trademark laws. In order
                      to request permission to reprint any material on this
                      Site (outside of personal, non-commercial use), please
                      write to:
                    </p>
                    <p>
                      Permissions Department<br />
                      Big Ideas Learning, LLC<br />
                       1762 Norcross Road <br />
                      Erie, PA 16510
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Linking</h3>
                    <p>
                      If you link to this Site, any such link must not damage,
                      dilute or tarnish the goodwill associated with Big Ideas
                      Learning, nor may the link create the false appearance
                      that your web site or organization is sponsored, endorsed
                      by, affiliated or associated with Big Ideas Learning.
                      You may not "frame" this Site.
                    </p>
                  </div>

                  <div class="space half">
                    <h3>Changes to These Terms of Use</h3>
                    <p>
                      Big Ideas Learning reserves the right, at its sole
                      discretion, to change, modify, add or delete portions of
                      these Terms at any time.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TermsOfUsePage;
