import React, {Component} from 'react';
import homepage_dog from '../images/homepage_dog.png';
import homepage_cat from '../images/homepage_cat.png';
import {
  NavLink
} from "react-router-dom";
import {gradeDisplay, buildSongURL, gradeCssClassName} from "../Helpers/Functions"

class GradeLandingPage extends Component {
    gradePicker = () => {
      let gradeid = this.props.match.params.gradeid;

      if(this.props.match.params.gradeid==null){
        gradeid = this.props.gradeid;
      }else{
        gradeid = this.props.match.params.gradeid;
      }
      
      const gradeIds = ["K", "1", "2", "3", "4", "5"];
      const list = [];
      gradeIds.forEach((grade) => {
          list.push(
            <div class={ (gradeid == grade) ? "active desktop-16 phone-33 grade grade-" + grade : "grade desktop-16 phone-33 grade-" + grade} >
              <NavLink to={`/grade/${grade}`}></NavLink>
            </div>
          );
      }, this);      
      return list;
    }

    gradeCard = () => {
        let gradeid = this.props.match.params.gradeid;
        if(this.props.match.params.gradeid==null){
          gradeid = this.props.gradeid;
        }else{
          gradeid = this.props.match.params.gradeid;
        }

        const books = this.props.books;
        const songs = [];
        let gradeBook = {};
        books.Books.forEach((book) => {
          if (gradeid == book.GradeID) {
            gradeBook = Object.assign({}, book.BookDetails);
          }
        }, this);
        
        return gradeBook;
    }

    bookCards = () => {
      let gradeid = this.props.match.params.gradeid;
       if(this.props.match.params.gradeid==null){
         gradeid = this.props.gradeid;
       }else{
         gradeid = this.props.match.params.gradeid;
       }

       const books = this.props.books;
       const cards = [];
       let gradeBook = {};

       books.Books.forEach((book) => {
         let songs = [];
         if (gradeid == book.GradeID) {
           gradeBook = Object.assign({}, book.BookDetails);
           gradeBook.Songs.forEach((song) => {
             songs.push(
               <div class="grade-song-card desktop-33 tablet-50">
                 <div class="space half">
                   <NavLink to={`/grade/${book.GradeID}/${buildSongURL(song.SongTitle)}/`}>
                     <div class="grade-song-image">
                       <img src={song.SongImage} alt={song.SongTitle} class={gradeCssClassName(gradeBook.BookGrade)} />
                     </div>
                     <div class="grade-song-title">
                       <h3>{song.SongTitle}</h3>
                       <p>
                         {song.SongObjective}
                       </p>
                     </div>
                   </NavLink>
                 </div>
               </div>
             )
           }, this);
           cards.push(
             <div class="grade-landing container content-container">
               <div class="space half">
                 <div class="title">
                   <h2>{gradeBook.BookTitle}</h2>
                 </div>
                 <div class="credits desktop-50 phone-100">
                   <ul>
                     <li>Stories by <NavLink to={'/about'}>{gradeBook.StoriesBy}</NavLink></li>
                     <li>Songs & Lyrics by <NavLink to={'/about'}>Michael Wiskar</NavLink></li>
                   </ul>
                 </div>
                 <div class="flex-container">
                   <div class="grade-songs">
                     <div class="flex-container">
                       {songs}
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           )
         }
       }, this);
       return cards;
     }

    render() {
      return (
          <div class="grade-landing-page">
            <div class="space half">
              {
                 <div class="grade-selectors container content-container">
                   <div class="space half-left half-right">
                     <div class="flex-container">
                       {this.gradePicker()}
                    </div>
                  </div>
                  <div class="grade-animal-cat">
                    <img src={homepage_cat} alt='' />
                  </div>
                  <div class="grade-animal-dog">
                    <img src={homepage_dog} alt='' />
                  </div>
                 </div>
              }
              {
                <div class="grade-name container content-container">
                  <div class="space half">
                    <div class="flex-container">
                      <div class="title">
                        <div class="space half">
                          <h1 class={gradeCssClassName(this.gradeCard().BookGrade)}>{gradeDisplay(this.gradeCard().BookGrade)}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {
                this.bookCards()
              }

            </div>
          </div>

      );
    }
}

export default GradeLandingPage;
