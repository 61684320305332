import React, {Component} from 'react';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import {buildSongURL, createMarkup} from "../Helpers/Functions";
import {
  NavLink
} from "react-router-dom";

class SheetMusicPage extends Component{

  displayButtons = (props, chosenSong) => {
    if (/Mobi/.test(navigator.userAgent)) {
      return (
        <div class="buttons">
          <a href={chosenSong.SheetMusicUrl} class="button" download >
            <span class="icon download"></span>
          </a>
        </div>
      )
    } else {
      return (
        <div class="buttons">
          <NavLink to={'/grade/' + props.match.params.gradeid + '/' + props.match.params.songurl + '/sheetmusic/pdfs/' +buildSongURL(chosenSong.SheetMusicTitle)} class="button">
            <span class="icon view"></span>
          </NavLink>
          <a href={chosenSong.SheetMusicUrl} class="button" download >
            <span class="icon download"></span>
          </a>
        </div>
      )
    }
  }

  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle) && this.props.match.params.gradeid === book.GradeID) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
        }
      });
    });

    return(
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class='page-resources flex-container'>
                      <h2>Sheet Music</h2>
                      <div class="page-description"><div class="desktop-100" dangerouslySetInnerHTML={createMarkup(chosenSong.SheetMusicDescription)} /></div>

                      <div class="desktop-25 phone-50 smallphone-100">
                        <div class="space one-forth">
                          <div class="page-resource">
                            <div class="image">
                              <img src={chosenSong.SongImage} alt={chosenSong.SongTitle} />
                            </div>
                            <div class="title">
                              {chosenSong.SheetMusicTitle}
                            </div>
                            {this.displayButtons(this.props, chosenSong)}
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={this.props.location.pathname} chosenSong={chosenSong}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SheetMusicPage;
