import React, { Component } from 'react';
import './App.css';

//Begin Importing Components
import HomePage from "./HomePage/HomePage"
import Header from "./Frame/Header"
import Footer from "./Frame/Footer"
import {
  HashRouter as Router,
  Route
} from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.books = this.props.data;
    //console.log(this.books);
    this.state = {selectedBook: "K", selectedSong: ""};
  } 

  updateBookSelection(GradeID) {
    this.setState({selectedBook: GradeID});
	//console.log('selected a book');
  }

  updateSongSelection(song) {
    this.setState({selectedSong: song});
    //console.log('selected a song');
  }

  render() {
    return (
      <Router>
        <div className="App">
          {/* Header */}
          <Header/>

          {/* Homepage Components */}
          <HomePage books={this.books} selectedBook={this.state.selectedBook} selectedSong={this.state.selectedSong}  updateBookSelection={this.updateBookSelection.bind(this)} updateSongSelection={this.updateSongSelection.bind(this)}/>

          {/* Footer */}
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
