import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import axios from 'axios';
import polyfills from './Helpers/polyfills.js';

var data = "";
axios.get('math_musicals.json').then(function(d) {
  ReactDOM.render(<App data={d.data}/>, document.getElementById('root'));
});

registerServiceWorker();
