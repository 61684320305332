import React, {Component} from 'react';
import activities_icon from '../images/nav-button-icons/activities_icon.svg';
//import purchase_icon from '../images/nav-button-icons/purchase_icon.svg';
import sheet_music_icon from '../images/nav-button-icons/sheet_music_icon.svg';
import song_icon from '../images/nav-button-icons/song_icon.svg';
import storybook_icon from '../images/nav-button-icons/storybook_icon.svg';
import {
  NavLink
} from "react-router-dom";

function storybookLink(props) {
  if (props.chosenSong.StoryUrl) {
    return (
      <li>
        <NavLink to={'/grade/' + props.gradeid +'/' + props.songurl +'/storybook'} class={ (props.pathName === '/grade/' + props.gradeid +'/' + props.songurl +'/storybook') ? "active nav-button" : "nav-button"}>
          <span class="icon"><img src={storybook_icon} alt="" /></span>
          Storybook
        </NavLink>
      </li>
    )
  }
}

function sheetMusicLink(props) {
  if (props.chosenSong.SheetMusicUrl) {
    return(
      <li>
        <NavLink to={'/grade/' + props.gradeid +'/' + props.songurl +'/sheetmusic'} class={ (props.pathName === '/grade/' + props.gradeid +'/' + props.songurl +'/sheetmusic') ? "active nav-button" : "nav-button"}>
          <span class="icon"><img src={sheet_music_icon} alt="" /></span>
          Sheet Music
        </NavLink>
      </li>
    )
  }
}

function activitiesLink(props) {
  if (props.chosenSong.Activities.length > 0) {
    return (
      <li>
        <NavLink to={'/grade/' + props.gradeid +'/' + props.songurl +'/activities'} class={ (props.pathName === '/grade/' + props.gradeid +'/' + props.songurl +'/activities') ? "active nav-button" : "nav-button"}>
          <span class="icon"><img src={activities_icon} alt="" /></span>
          Activities
        </NavLink>
      </li>
    )
  }
}

class BookPageMenu extends Component {
  render() {
    return (
      <div class="page-sidebar desktop-20 phone-100">
        <ul>
          <li>
            <NavLink to={'/grade/' + this.props.gradeid +'/' + this.props.songurl +'/'} class={ (this.props.pathName === '/grade/' + this.props.gradeid +'/' + this.props.songurl +'/') ? "active nav-button" : "nav-button"}>
              <span class="icon"><img src={song_icon} alt="" /></span>
              Song
            </NavLink>
          </li>
          {storybookLink(this.props)}
          {sheetMusicLink(this.props)}
          {activitiesLink(this.props)}
        </ul>
      </div>
    )
  }
}

export default BookPageMenu;
