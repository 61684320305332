import React, {Component} from 'react';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import {buildSongURL, createMarkup} from "../Helpers/Functions";
import {
  NavLink
} from "react-router-dom";

class SheetMusicPagePdf extends Component{

  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};
    let chosenPdf = "";
    let chosenPdfTitle = "";

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle)) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
          chosenPdf = chosenSong.SheetMusicUrl;
          chosenPdfTitle = chosenSong.SheetMusicTitle;
        }
      });
    });

    if(chosenPdfTitle == "Best Friends (I Get You and You Get Me)"){
        switch(this.props.match.params.gradeid.toLowerCase()){
          case "k":
            bookGrade = "Kindergarten";
            break;
          case "1":
            bookGrade = "First Grade";
            break;
          case "2":
            bookGrade = "Second Grade";
            break;
            case "3":
            bookGrade = "Third Grade";
            break;
            case "3":
            bookGrade = "Fourth Grade";
            break;
            case "5":
            bookGrade = "Fifth Grade";
            break;
        }
    }

    return(
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class='page-resources flex-container'>
                      <div class="page-resource-pdf-title desktop-50 phone-100">
                        <h2><NavLink to={'/grade/' + this.props.match.params.gradeid +'/' + this.props.match.params.songurl +'/sheetmusic'}>Sheet Music</NavLink> / {chosenPdfTitle}</h2>
                      </div>
                      <div class="page-resource-pdf-download desktop-50 phone-100">
                        <a class="default-button" href={chosenPdf} download>Download</a>
                      </div>
                      <div dangerouslySetInnerHTML={createMarkup(chosenSong.SheetMusicDescription)} />
                      <div class="page-pdf flex-iframe">
                        <object data={chosenPdf} type="application/pdf" width="100%" height="100%"></object>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={"/grade/"+this.props.match.params.gradeid+"/"+this.props.match.params.songurl+"/sheetmusic"} chosenSong={chosenSong}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SheetMusicPagePdf;
