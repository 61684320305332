import React, {Component} from 'react';
import logo from '../images/logo.svg';
import header1 from '../images/header/violet.png';
import header2 from '../images/header/blue.png';
import header3 from '../images/header/green.png';
import header4 from '../images/header/yellow.png';
import header5 from '../images/header/orange.png';
import header6 from '../images/header/red.png';

class Header extends Component {
  render() {
    return (

      <div class="page-header">
        <div class="container flex-container">
          <div class="logo desktop-16">
            <div class="space half">
              <a href="./">
                <img src={logo} alt="Math Musicals" />
              </a>
            </div>
          </div>
          <div class="desktop-0 order-0"></div>
          <div class="grade-animal violet desktop-14">
            <img src={header1} alt="" />
          </div>
          <div class="grade-animal blue desktop-14">
            <img src={header2} alt="" />
          </div>
          <div class="grade-animal green desktop-14">
            <img src={header3} alt="" />
          </div>
          <div class="grade-animal yellow desktop-14">
            <img src={header4} alt="" />
          </div>
          <div class="grade-animal orange desktop-14">
            <img src={header5} alt="" />
          </div>
          <div class="grade-animal red desktop-14">
            <img src={header6} alt="" />
          </div>
          <div class="desktop-0 order-7"></div>
        </div>
      </div>
    )
  }
}

{/* Generic content
<div class="container content-container">
  <div class="space">
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <h5>Heading 5</h5>
    <h6>Heading 6</h6>
    <p>
      Bacon ipsum dolor amet hamburger fatback venison meatball beef. Cupim sirloin drumstick, swine strip steak pork belly shankle ham sausage frankfurter jerky turducken. Bresaola short ribs tail kevin drumstick kielbasa salami swine bacon. Chuck leberkas turkey short loin flank.
    </p>
    <h3>Icons</h3>
    <i class="material-icons md-18">face</i>
    <i class="material-icons md-24">face</i>
    <i class="material-icons md-36">face</i>
    <h3>Buttons</h3>
    <a class="default-button" href="#">Download</a>
  </div>
</div> */}


export default Header;
