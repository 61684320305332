import React, {Component} from 'react';
import big_ideas_learning_logo from '../images/bil_logo.svg';
import big_ideas_math_logo from '../images/bilmath_logo.svg';
import {
  NavLink
} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div class="footer">
        <div class="container flex-container">
          <div class="logo desktop-40 tablet-100">
            <a href="http://www.bigideaslearning.com/" target="_blank" rel="noopener noreferrer">
              <img src={big_ideas_learning_logo} alt="Big Ideas Learning" />
            </a>
            <a href="http://www.bigideasmath.com/" target="_blank" rel="noopener noreferrer">
              <img src={big_ideas_math_logo} alt="Big Ideas Math" />
            </a>   
          </div>
          <div class="nav desktop-30 tablet-100">
            <div class="space half">
              <ul>
                <li>
                  <NavLink to={'/about'}>About</NavLink>
                </li>
                <li>
                  <NavLink to={'/privacy'}>Privacy</NavLink>
                </li>
                <li>
                  <NavLink to={'/terms-of-use'}>Terms of Use</NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="copyright desktop-30 tablet-100">
            <div class="space half">
              <p>
                &copy;Big Ideas Learning, LLC <br />
                All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Footer;
