import React, {Component} from 'react';
import {
  NavLink
} from "react-router-dom";
import {gradeDisplay, gradeCssClassName} from "../Helpers/Functions";

class BookPageHeader extends Component {
  render() {
    return(
      <div class="page-subheader">
        <div class="container flex-container">
          <div class="desktop-20">
            <div class="page-backbutton">
              <NavLink to={`/grade/` + this.props.gradeid}></NavLink>
            </div>
          </div>
          <div class="desktop-80 flex-container page-subheader">
            <div class="page-title">
              <div class="space half">
                <h1 class={gradeCssClassName(this.props.bookGrade) + " small"}>{gradeDisplay(this.props.bookGrade)}</h1>
                <h1>{this.props.chosenSong.SongTitle}</h1>
                <h2>{this.props.chosenSong.SongObjective}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BookPageHeader;
