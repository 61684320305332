import React, { Component } from 'react';
import big_ideas_learning_logo from '../images/bil_logo.svg';
import jill_larson from "../images/jill_larson.png";
import michael_wiskar_horizontal from "../images/about_michael_wiskar.png";

class AboutPage extends Component {
  render() {

    return (
      <div>
        <div class="page-subheader">
          <div class="container flex-container">
            <div class="space">
              <h1>About</h1>
              <p>
                Math Musicals was developed by talented artists and mathematics experts from Big Ideas Learning to combine math, storytelling, and music.
              </p>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="space half">
            <div class="container content-container">
              <div class="space">
                <h1 class="center">Math Musicals</h1>
                <div class="space">
                  <p>
                    In collaboration with storytellers Jill Larson and composer Michael Wiskar, Big Ideas Learning is proud to offer Math Musicals, a storybook and music series designed to engage K-5 students to explore math topics in a fun, memorable way.
                  </p>
                  <p>
                    With Math Musicals, students enter the fictional world of best buddies Newton, a loyal and curious dog, and Descartes, a clever and adventurous cat. Their engaging stories show math in context of friendship, travel, fashion, athletics, and more. Students learn along with Newton and Descartes as they encounter Kindergarten through Grade 5 math topics in delightful, and sometimes wacky, situations.
                  </p>
                  <p>
                    Math Musicals makes learning visible. To dig deeper into the math topic, Differentiated Rich Math Tasks are provided to foster learning through discovery and reflection.
                  </p>
                </div>
              </div>
            </div>
            <div class="container content-container">
              <div class="space">
                <h1 class="center">Meet the Creators</h1>
                <h2 class="center">Math, Storytelling and Music</h2>
              </div>
                <div class="space">
                  <div class="flex-container">
                    <div class="desktop-25 phone-100 space half">
                      <div class="space noleft noright nobottom" id="jill_larson">
                        <img src={jill_larson} alt="Jill Larson" class="flex-image"  />
                      </div>
                    </div>
                    <div class="desktop-70 tablet-100" style={{'marginTop': + .5 + "em"}}>
                      <div class="space">
                        <h3>Jill Larson</h3>
                        <p>
                          For over 25 years, Jill has worked in educational publishing with an emphasis on content development, editorial, and market research. Currently, Jill serves as a co-owner of Big Ideas Learning and its parent company, Larson Texts Inc.
                        </p>
                        <p>
                          Jill received her bachelor’s degree in English literature from Lewis and Clark College in 1989. Since 1992, Jill has overseen and edited award-winning interactive educational products such as <i>Oval Office: Challenge of the Presidency</i> and <i>Larson’s Elementary Math</i>. She has thoroughly enjoyed writing the <i>Math Musicals</i> stories that correspond to the <i>Big Ideas Math: Modeling Real Life K-5 program.</i></p>
                        <p>
                          Outside of work, Jill serves on the board of University Child Development School, an innovative K-5 school based in Seattle. She previously served on the Board of Trustees for KidsQuest Children's Museum in Bellevue.
                        </p>
                        <p>
                          Jill resides in Washington state with her husband Edward, their three children, and their yellow lab, Mulligan.
                        </p>
                      </div>
                    </div>
                  </div>                 
                  <div class="flex-container">
                    <div class="desktop-25 phone-100 space half">
                      <div class="space noleft noright nobottom">
                        <img src={michael_wiskar_horizontal} alt="Michael Wiskar" class="flex-image" />
                      </div>
                    </div>
                    <div class="desktop-70 tablet-100" style={{'marginTop': + .5 + "em"}}>
                      <div class="space">
                        <h3>Michael Wiskar</h3>
                        <p>
                          Michael (Mike) Wiskar is a Leo-nominated composer, songwriter, independent music producer, vocalist, and multi-instrumentalist.
                        </p>
                        <p>
                          A graduate of the Music Program at MacEwan University as well as Langara’s Digital Music Production Program, Mike began his music career in his teens as a performer in rock, country, and jazz bands before working as a songwriter for various artists in Quebec. There he worked closely with Myles Goodwyn of legendary Canadian rock band April Wine, among other top level songwriters and producers.
                        </p>
                        <p>
                        Mike has since written and produced songs and music scores for theater, short films, full length and series documentaries, choirs, contemporary artists, and numerous television series (Operation Vacation, Psycho Kitty, Men’s Fashion Insider, Kitty 911, and Favorite Places, to name just a few).
                        </p>
                      </div>
                    </div>
                  </div>
                
              </div>
            </div>
            <div class="container content-container">
              <div class="space">
                <div class="about-logo">
                  <a href="http://www.bigideasmath.com/" target="_blank">
                    <img src={big_ideas_learning_logo} alt="Big Ideas Learning" />
                  </a>
                </div>
                <div class="space">
                  <p>
                    Big Ideas Learning, LLC and Larson Texts, Inc. were founded by Dr. Ron Larson to create instructional materials that provide a coherent mathematics curriculum to support world-class mathematics education.
                  </p>
                  <p>
                    Big Ideas Learning works with educators in every step of the development process. They are the publishers of the highly successful Big Ideas Math series. Using mathematical and pedagogical research, the Big Ideas Math program focuses on fewer topics at each grade level, providing a narrower and deeper course of study that leads students to mastery of each benchmark as they move from grade to grade. Big Ideas Learning provides students and teachers with all the tools they need to succeed from Kindergarten through high school mathematics courses.
                  </p>
                  <p>
                    Dr. Ron Larson's textbooks are known for their readability, accuracy, and real-life applications. They are used by over five-million students each year. Dr. Larson is deeply committed to providing innovative and coherent print and online materials to the education community for nearly 40 years.
                    Big Ideas Math is exclusively distributed by National Geographic Learning.
                  </p>
                </div>
              </div>
            </div>
            {/* <div class="container content-container">
              <div class="space">
                <h1 class="center">Acknowledgments</h1>
                <h2 class="center">An expression of thanks and gratitude</h2>
                <div class="space">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non lorem in eros tincidunt ornare. Pellentesque eleifend felis vel sodales malesuada.                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non lorem in eros tincidunt ornare. Pellentesque eleifend felis vel sodales malesuada.
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non lorem in eros tincidunt ornare. Pellentesque eleifend felis vel sodales malesuada.                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non lorem in eros tincidunt ornare. Pellentesque eleifend felis vel sodales malesuada.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default AboutPage;
