import React, {Component} from 'react';

class CreditsPage extends Component{
  render() {

    return(
      <div>
        <div class="page-subheader">
          <div class="container flex-container">
            <div class="space">
              <h2>Credits</h2>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="space half">
            <div class="container content-container">
              <div class="space">
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CreditsPage;
