import React, {Component} from 'react';

class PrivacyPage extends Component{
  render() {

    return( <div> <div class="page-subheader"> <div class="container flex-container"> <div class="space"> <h2>Privacy
    Policy</h2><br /> <p> This Privacy Policy (“Policy”), effective 04/24/2021, tells you about Larson Texts’ privacy
    practices governing personal information we may collect when you visit our  mathmusicals.com website, and how we may
    use and share that information. Your prior activities on the site may have been governed by an earlier version of
    this Policy.</p><p>From time to time, we may use your information for new, unanticipated uses not previously
    disclosed in our Policy. If our information practices do change materially, we will post the changes to our site
    and revise our Policy accordingly. </p> </div> </div> </div> <div class="page-content"> <div class="space half">
    <div class="container content-container"> <div class="space"> <div class="space half"> <h3>What Information Do We
    Collect?</h3><p></p><h4>Personal Information:</h4> <p>We do not collect personal information from users of this site.  </p>
    <h4>Other user information we collect:</h4> <p>For each visitor to our site, we automatically gather certain other
    potentially identifying information and store it in log files.  This information includes (as applicable) browser
    type, internet service provider (ISP), referring/exit pages, your operating system, date/time stamp, processor or
    device serial number, unique device identifier, and clickstream data.  We collect and store this information on an
    individual basis and in aggregate, or combined, form.</p><h4>“Do Not Track” Signals.</h4> <p> We do not currently
    have the capability to recognize browser “Do Not Track” signals. We adhere to the standards set forth in this
    privacy policy. </p> <h4>Cookies</h4> <p> A cookie is a small text file that is stored on a user’s computer for
    record-keeping purposes.  If you reject cookies, you may still use our site, but your ability to use some areas of
    our site will be limited.  We use secure session cookies to make it easier for you to navigate our site.  We use
    session cookies to record session information, such as which web pages a user has visited, and to track user
    activity on the site.  These cookies are deleted when you close your web browser.  </p> <h4>Analytics</h4> <p> To
    determine how many users visit our site, how often they visit the site, and to better understand the areas of
    greatest interest to our visitors, we use a tools called “Google Analytics” to compile this information for us. As a
    result of your visit to this site, Google may collect information such as your domain type, your IP address and
    clickstream information. For more information about the analytics companies’ ability to use and share information
    about your visits to this site, see <a href="http://www.google.com/intl/en/policies/privacy/"
    target="_blank">http://www.google.com/intl/en/policies/privacy/</a>. </p><h4>Advertising</h4><p>We do not use
    third-party ad networks, targeted advertising, or display ads on this site.</p> </div>

                <div class="space half"> <h3>How Do We Use and Share the Information We Collect?</h3> <p> We use
                information we collect to analyze trends, to administer the site, and to track users’ movements around
                the site.  We also use this information to improve the site and to make it more useful to visitors.  We
                do not share information collected on this site with third parties.</p> <p> It is possible that, at some
                time in the future, our company may be sold along with its assets, or may engage in business
                transactions in which customer information is one of the assets transferred. In such a case, the
                customer information which we have gathered may be one of the business assets we transfer. </p> </div>

                <div class="space half">
                  <h3>How Long Do We Keep the Information We Collect?</h3>
                  <p>
                    We will retain site use information in accordance with our continuing business need to improve our site. 
                  </p>
                </div>

                <div class="space half"> <h3>Security</h3> <p> We follow generally accepted industry standards to
                protect the information we collect, and to guard that information against loss, misuse, or alteration.  
                 </p> </div>

                <div class="space half"> <h3>Links to Third Party Sites</h3> <p> This Policy applies only to information
                collected by this site.  From time to time, this site may link you to other sites (“Linked Sites”) that
                are not owned by us.  We do not control the collection or use of any information, including personal
                information, that occurs during your visit to the Linked Sites.  Further, we make no representations
                about the privacy policies or practices of the Linked Sites, and we are not responsible for their
                privacy practices.  </p> <p> Be careful of disclosing any of your personally identifiable information
                when leaving our site.  We encourage you to be aware when you leave our site and to read the privacy
                statements of every site that collects personally identifiable information.  </p> </div>

                <div class="space half"> <h3>Children’s Privacy</h3>
<p>This site is not intended or designed to attract children under the age of 13. We do not collect personally identifiable information from any person we actually know is a child under the age of 13.</p></div>

<div class="space half"> <h3>California Privacy Rights</h3> <p>Pursuant to California Civil Code Section 1798.83, users
who are California residents can request certain information about the sharing of their own personal information with
third parties for direct marketing purposes during the past year. The information which you have a right to receive
includes the categories of personal information disclosed by us for direct marketing purposes during the preceding
calendar year and the names of the companies that received such information. You are entitled to receive a copy of this
information in a standardized format, and the information will not be specific to you individually. </p> <p>Pursuant to
the California Consumer Protection Act, (“CCPA”), California residents have additional rights with respect to their
personal information.  Please see our <a href="https://www.larsontexts.com/privacy-policy/ccpa/" target="_blank">CCPA Notice</a> for details.</p> <p>California residents can request this disclosure by sending a request to us using the
contact information provided below. Please provide sufficient information to allow us to identify you in our
records.</p></div>

                <div class="space half">
                  <h3>Questions About Our Privacy Policy and Practices</h3>
                  <p>
                    If you have any questions about the Policy or our privacy
                    practices, you may contact:
                  </p>
                  <p>
                    Larson Texts<br />1762 Norcross Road<br />Erie, PA 16510<br />(877) 552-7766<br />legal@larsontexts.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrivacyPage;
