import React, { Component } from 'react';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import { buildSongURL } from "../Helpers/Functions";
import google_icon from '../images/yt_logo_rgb_dark.png';
import amazon_icon from '../images/amazon-appstore-badge.png';
import itunes_icon from '../images/itunes-store-badge.png';
import ngl_icon from '../images/ngl_badge.png';

class PurchasePage extends Component {
  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};
    let purchases = [];
    let purchaseLinkArray = [];

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle) && this.props.match.params.gradeid === book.GradeID) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
          book.BookDetails.Purchases.forEach((purchase) => {
            let hasComingSoon = false;
            purchase.PurchaseLinks.forEach((purchaseLinks) => {
              if (purchaseLinks.PurchaseLinkType === 1 && purchaseLinks.PurchaseLinkUrl != "#") {
                if (purchaseLinks.PurchaseLinkUrl == "") {
                  purchaseLinkArray.push(
                    <div class="purchase-link"><img src={google_icon} alt="Coming Soon" style={{'opacity': + .5 }}/></div>
                  )
                  hasComingSoon = true;
                } else {
                purchaseLinkArray.push(
                  <div class="purchase-link"><a href={purchaseLinks.PurchaseLinkUrl} target="_blank"><img src={google_icon} alt="" /></a></div>
                )
              }
              } else if (purchaseLinks.PurchaseLinkType === 2 && purchaseLinks.PurchaseLinkUrl != "#") {
                if (purchaseLinks.PurchaseLinkUrl == "") {
                  purchaseLinkArray.push(
                    <div class="purchase-link"><img src={itunes_icon} alt="Coming Soon" style={{'opacity': + .5 }}/></div>
                  )
                  hasComingSoon = true;
                } else {
                  purchaseLinkArray.push(
                    <div class="purchase-link"><a href={purchaseLinks.PurchaseLinkUrl} target="_blank"><img src={itunes_icon} alt="" /></a></div>
                  )
                }
              } else if (purchaseLinks.PurchaseLinkType === 3 && purchaseLinks.PurchaseLinkUrl != "#") {
                if (purchaseLinks.PurchaseLinkUrl == "") {
                  purchaseLinkArray.push(
                    <div class="purchase-link"><img src={amazon_icon} alt="Coming Soon" style={{'opacity': + .5 }}/></div>
                  )
                  hasComingSoon = true;
                } else {
                  purchaseLinkArray.push(
                    <div class="purchase-link"><a href={purchaseLinks.PurchaseLinkUrl} target="_blank"><img src={amazon_icon} alt="" /></a></div>
                  )
                }
              } else if (purchaseLinks.PurchaseLinkUrl != "#") {
                purchaseLinkArray.push(
                  <div class="purchase-link"><a href={purchaseLinks.PurchaseLinkUrl} target="_blank"><img src={ngl_icon} alt="" /></a></div>
                )
              }
            })
             if(hasComingSoon){
                purchaseLinkArray.push(
                  <div><p class="coming-soon">Coming Soon!</p></div>
                )
              }
            if (purchaseLinkArray.length > 0) {
              purchases.push(
                <div class="purchase-details flex-container desktop-100">
                  <div class="purchase-thumbnail desktop-20 phone-20 smallphone-100">
                    <img src={purchase.PurchaseThumbnail} alt={purchase.PurchaseDescription}/>
                  </div>
                  <div class="purchase-data desktop-70 phone-70 smallphone-100">
                    <h2>{purchase.PurchaseTitle}</h2>
                    <p>{purchase.PurchaseDescription}</p>
                    <div class="purchase-links flex-container">{purchaseLinkArray}</div>
                  </div>
  
                </div>
              )}
            
            purchaseLinkArray = [];
          })
        }
      });
    });

    return (
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class='page-resources flex-container'>
                      <h2>Purchase</h2>
                      {purchases}
                    </div>
                  </div>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={this.props.location.pathname} chosenSong={chosenSong}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PurchasePage;
