import React, {Component} from 'react';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import {buildSongURL, createMarkup} from "../Helpers/Functions";
import {
  NavLink
} from "react-router-dom";

class ActivityPage extends Component{

  displayButtons = (props, activity) => {
    if (/Mobi/.test(navigator.userAgent)) {
      return (
        <div class="buttons">
          <a href={activity.ActivityUrl} class="button" download >
            <span class="icon download"></span>
          </a>
        </div>
      )
    } else {
      return (
        <div class="buttons">
          <NavLink to={'/grade/' + props.match.params.gradeid +'/' + props.match.params.songurl +'/activities/pdfs/'+buildSongURL(activity.ActivityTitle)} class="button">
            <span class="icon view"></span>
          </NavLink>
          <a href={activity.ActivityUrl} class="button" download >
            <span class="icon download"></span>
          </a>
        </div>
      )
    }
  }

  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};
    let activities = [];

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle) && this.props.match.params.gradeid === book.GradeID) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
          chosenSong.Activities.forEach((activity) => {
            activities.push(
              <div class="desktop-25 phone-50 smallphone-100">
                <div class="space one-forth">
                  <div class="page-resource">
                    <div class="image">
                      <img src={activity.ActivityThumbnail} alt={activity.ActivityTitle} />
                    </div>
                    <div class="title">
                      {activity.ActivityTitle}
                    </div>
                    {this.displayButtons(this.props, activity)}
                  </div>
                </div>
              </div>
            )
          })
          if (this.props.selectedBook === book.GradeID) {
            bookGrade = book.BookDetails.BookGrade;
          };
        }
      });
    });

    return(
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class='page-resources flex-container'>
                      <h2>Activities</h2>
                      <div dangerouslySetInnerHTML={createMarkup(chosenSong.ActivityDescription)} />
                      {activities}
                    </div>
                  </div>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={this.props.location.pathname} chosenSong={chosenSong}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityPage;
