import React, {Component} from 'react';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import {buildSongURL, createMarkup} from "../Helpers/Functions";

class StorybookPage extends Component {

  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle) && this.props.match.params.gradeid === book.GradeID) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
        }
      });
    });

    return(
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class='page-resources flex-container'>
                      <h2>Storybook</h2>
                      <div dangerouslySetInnerHTML={createMarkup(chosenSong.StoryDescription)} />
                      <div class="page-pdf flex-iframe">
                        <iframe src={chosenSong.StoryUrl} title={chosenSong.StoryTitle} width="100%" height="100%"></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={this.props.location.pathname} chosenSong={chosenSong}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default StorybookPage;
