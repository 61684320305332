export function buildSongURL(songTitle) {
   return songTitle.replace(/[.,\/#!?$%\^&\*;:{}=\-_'~()]/g,"").replace(/\s+/g,"-").toLowerCase();
}

export function gradeDisplay(grade) {
  let grades = ["First", "Second", "Third", "Fourth", "Fifth"];
  if (grade === "Kindergarten") {
    return "Kindergarten";
  } else {
    grade--;
    return grades[grade] + " Grade";
  }
}

export function gradeCssClassName(grade) {
  let grades = ["First", "Second", "Third", "Fourth", "Fifth"];
  if (grade === "Kindergarten") {
    return "Kindergarten";
  } else {
    grade--;
    return grades[grade] + "Grade";
  }
}

export function createMarkup(string) { return {__html: string}; };
