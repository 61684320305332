import React, {Component} from 'react';
import {Collapse} from 'react-collapse';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import BookPageMenu from "./BookPageMenu";
import BookPageHeader from "./BookPageHeader";
import {buildSongURL, createMarkup} from "../Helpers/Functions";
class SongPage extends Component {

  constructor(props) {
    super(props);
    this.state = {isOpened: false};
  }

  componentDidMount(){
    window.renderMathJax();
  }

  render() {
    const books = this.props.books;
    let bookGrade = "";
    let chosenSong = {};
    const {isOpened} = this.state;
    const overlayStatus =  isOpened ? "no-overlay" : "apply-overlay";

    books.Books.forEach((book) => {
      book.BookDetails.Songs.forEach((key) => {
        if (this.props.match.params.songurl === buildSongURL(key.SongTitle) && this.props.match.params.gradeid === book.GradeID) {
          bookGrade = book.BookDetails.BookGrade;
          chosenSong = Object.assign({}, key);
        }
      });
    });

    return(
      <div>
        <BookPageHeader gradeid={this.props.match.params.gradeid} chosenSong={chosenSong} bookGrade={bookGrade}/>
        <div class="page-content">
          <div class="space one-forth">
            <div class="container flex-container desktop-rtl">
              <div class="desktop-80 phone-100">
                <div class="container content-container">
                  <div class="space">
                    <div class="page-video">
                      <div class="video-container">
                        <div class="video-container-padding">
                          <iframe src={chosenSong.VideoUrl} title={chosenSong.SongTitle} allowfullscreen="allowfullscreen" webkitallowfullscreen="webkitallowfullscreen" mozallowfullscreen="mozallowfullscreen"></iframe>
                        </div>
                      </div>
                    </div>
                    <div class="page-lyrics">
                      <div class="lyrics-controls">
                        <label class={"page-video-button-lyrics " + isOpened}>
                          <h3>Lyrics</h3>
                          <div class={'arrow ' + isOpened}></div>
                          <input class={'hidden-box'} type="checkbox" checked={isOpened} onChange={({target: {checked}}) => this.setState({isOpened: checked})}></input>
                        </label>
                      </div>
                      <Collapse isOpened={ isOpened } className="collapsable" onHeightReady={this.onHeightReady}>
                        <div class="page-video-lyrics">
                          <div dangerouslySetInnerHTML={createMarkup(chosenSong.LyricsUrl)} />
                        </div>
                        <div class={overlayStatus}></div>
                      </Collapse>
                    </div>
                  </div>
                </div>
                <div class="container content-container">
                  <Tabs defaultTab="aboutSong" onChange={(tabId) => {console.log(tabId) }}>
                    <TabList class="songTabs">
                      <div class="tab left-tab">
                        <Tab tabFor="aboutSong"><h3>About the Song</h3></Tab>
                      </div>
                      <div class="tab right-tab">
                        <Tab tabFor="standards"><h3>About the Math</h3></Tab>
                      </div>
                    </TabList>
                    <div class="space">
                      <TabPanel tabId="aboutSong">
                        <p dangerouslySetInnerHTML={createMarkup(chosenSong.SongAboutUrl)}></p>
                      </TabPanel>
                      <TabPanel tabId="standards">
                        <p dangerouslySetInnerHTML={createMarkup(chosenSong.SongMathUrl)}></p>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
              <BookPageMenu gradeid={this.props.match.params.gradeid} songurl={this.props.match.params.songurl} pathName={this.props.location.pathname} chosenSong={chosenSong} />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default SongPage;
